.dropdown-toggle-categoria{
    padding-top: 10px;
	padding-right: 10px;
    padding-left: 10px;
    padding-right: 10px;

    background: transparent !important;
    border: 0 !important;

    box-shadow: none !important;

    outline: none !important;

	font-size: 0;
}

.dropdown-toggle-categoria:hover{

    background: transparent !important;

}



.dropdown-toggle-categoria svg {
    width: 16px;
    height: 36px;
    color: #09aa36 !important;
}

.dropdown-menu-container {
    background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 6px 12px #0000001A;
border-radius: 10px;
    border-top: 8px solid #09aa36;



}
.dropdown-item-categoria{
    background:transparent !important;
    text-align: left;
font: normal normal normal 14px/19px Roboto;
letter-spacing: 0px;
color: #09AA36 !important;

    padding: 10px 10px;
    width: calc(100% - 20px);

}

.dropdown-item-categoria svg{
    width: 16px !important;
height: 16px !important;
color: #09AA36 !important;
}

.modal-categoria-mask-input{
    text-align: left;
	font: normal normal 500 16px/21px Roboto;
	height: 22px;
	letter-spacing: 0px;
	color: #09aa36;

	max-width: 85%;
	width: 200px;

	background: transparent;
	border: 0;
	border-bottom: 1px solid #09aa36;
	padding-left: 5px;
	padding-right: 5px;

	margin: 0;}


  .dropdown-toggle-button-categoria{  background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #09aa36 !important;
	border-radius: 10px;
	opacity: 1;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-right: 6px;
	padding-left: 6px;

	font-size: 0;

	margin-right: 5px;

	
}

.dropdown-toggle-button-categoria svg {
    width: 16px;
    height: 16px;
    color: #09aa36;
}


.dropdown-toggle-buttton-categoria:hover{
    opacity: 0;
    background: transparent !important;

}

.dropdown-toggle-elip{
    background: #ffffff 0% 0% no-repeat padding-box !important;
	border: 1px solid #09aa36 !important;
	border-radius: 10px;
	opacity: 1;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-right: 12px;
	padding-left: 12px;

	text-align: center;
	font: normal normal normal 12px/16px Roboto;
	letter-spacing: 0px;
	color: #09aa36 !important;
	opacity: 1;

    box-shadow: none !important;

	margin-right: 5px;
    font-size: 0;

	

}

.dropdown-toggle-elip svg {
    width: 16px;
    height: 16px;
    color: #09aa36;
}

.dropdown-toggle-elip:hover{
    background-color: transparent !important;
}

.dropdown-toggle-elip:focus{
    background-color:  transparent !important;
}
