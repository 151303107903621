
.ranking-text-medium{
    text-align: right;
font: normal normal 500 11px/15px Roboto;
letter-spacing: 0px;
color: #222222;
opacity: 1;
    margin-right: 6px;
  }


  .dropdown-dash-toggle{
   font-size: 0 !important;
    background: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    
  }

 .dropdown-dash-toggle ::after{
     color:'#FFF'
 }


 .dropdown-none{
     background: transparent !important;
     border: 0 !important;
     box-shadow: none !important;
     font-size: 0 !important;
     padding: 0 !important;
     

 }