.Login-logo-bill{
    width: 84px;
    height: 30px;

}

.Login-logo-bill path{
    
}

.logo-bill-image path{
    fill:#FFFFFF
}

.login-user-mask{
    padding-left: 8px;
    background: transparent;
    border-bottom-width: 1px;
    border-color: #18AA36;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    width: 100%;

    text-align: left;
font: normal normal 500 16px/21px Roboto;
letter-spacing: 0px;
color: #18AA36;
}