.dropdown-situacao-toggle{
    background: transparent !important;
	border: 1px solid #09aa36 !important;
	border-radius: 10px;
	opacity: 1;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-right: 12px;
	padding-left: 12px;

	text-align: center;
    text-align: left;
    font: normal normal 500 14px/19px Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;	letter-spacing: 0px;
	
	opacity: 1;

    box-shadow: none !important;

	margin-right: 5px;



}

.dropdown-situacao-toggle:hover{
    background: #72f898 !important;
}

.dropdown-situacao-toggle:focus{
    background: #72f898 !important;
}
